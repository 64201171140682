import * as React from "react"
import { useEffect } from 'react';
import { Link } from "gatsby"

import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import SideBar from '../../../components/sidebar';
import SixtyFivePlusNav from '../../../components/nav/SixtyFivePlus';
import LearnMore from '../../../components/learnmore';
import SpeakWithAdvisor from '../../../components/ui/SpeakWithAdvisor';

function toggleAccordion(event) {
	let accordionContainer = this.nextElementSibling;
	this.classList.toggle("open");
	if (this.classList.contains('open')) {
		accordionContainer.setAttribute("aria-hidden", "false");
	} else {
		accordionContainer.setAttribute("aria-hidden", "true");
	}

	if (!accordionContainer.classList.contains('open')) {
		accordionContainer.classList.add('open');
		accordionContainer.style.height = 'auto';
	
		let height = accordionContainer.clientHeight + 'px';
	
		accordionContainer.style.height = '0px';
	
		setTimeout(function () {
			accordionContainer.style.height = height;
		}, 0);
	} else {
		accordionContainer.style.height = '0px';		
		accordionContainer.addEventListener('transitionend', function () {
			accordionContainer.classList.remove('open');
		}, {
			once: true
		});
	}
}

const SixtyFivePlusWhen = ({ location }) => {
	useEffect(() => {
		document.querySelectorAll(".accordiontrigger").forEach(trigger => {
			trigger.removeEventListener('click', toggleAccordion);
			trigger.addEventListener('click', toggleAccordion);
		});
	});

	return (
		<Layout location={location} heading1="65+ (first time enrolling)" heading2="Understanding your Medicare Options.">
			
			<Seo title="Know What To Do When | 65+ | Medicare Basics" meta={[{description: 'As soon as you turn 64, you should start getting ready for Medicare. If you\'ve decided to enroll in Medicare, this timeline offers suggestions for what you need to do to stay on track.'}]} bodyclass="medicare-basics section2 subpage" />
			<SixtyFivePlusNav />
				
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">

						<main>
							<h3>Know what to do when</h3>
							
							<p>As soon as you know when you are going to retire or no longer receive your current coverage, you should start gathering information to make sure you get the coverage you need.</p>
							
							<div className="whatwhen-explained">
								
								<div className="accordion">
									<div className="accordion-item">
										<button type="button" id="accordion-trigger-01" class="accordiontrigger" aria-controls="accordion-content-01">
											Before you retire
										</button>
										<div id="accordion-content-01" className="accordion-content" aria-labelledby="accordion-trigger-01" aria-hidden="true">
											<div className="contentwrap">
												<ul>
													<li key={1}>Learn about Medicare and the different types of plans. <Link to="/medicare-basics/65-plus/learn-how-medicare-works/">Start here</Link>.</li>
													<li key={2}>Talk to your employer about any plans offered to retirees. </li>
													<li key={3}>Make a list of your regular doctors and prescription drugs to make it easy for you to compare plans. </li>
													<li key={4}>Get a picture of your health care history. Ask yourself the following questions:
														<ul>
															<li key={41}>What did you pay in the past year? Look at your TOTAL costs, including premiums, deductibles, copays and coinsurance.</li>
															<li key={42}>How many times did you go to the doctor?</li>
															<li key={43}>How many hospital stays did you have?</li>
															<li key={44}>Do you plan on traveling?</li>
														</ul>
													</li>
													<li key={5}>Compare different Medicare plans to find the coverage and premiums that work best for you.
														<ul>
															<li key={51}>Need help picking a plan? <a href="https://bestplanforme.mvphealthcare.com/?sourcesite=uvm" className="external">Get a personalized plan recommendation</a></li>
															<li key={52}>TIP: Medicare Star Quality Ratings offer an objective way to compare Medicare health plans. <Link to="/about/star-rating/">Learn more about Medicare Star Ratings</Link>.</li>
															<li key={53}>TIP: When comparing plans, be sure to look at the maximum amount of costs you could incur, or the out-of-pocket maximum.</li>
															<li key={54}><Link to="/find-a-doctor/">See if your doctors are covered</Link></li>
															<li key={55}><Link to="/rx-information/">See if your prescriptions are covered</Link></li>
															<li key={56}><Link to="/plans/uvm-health-advantage/">Compare UVM Health Advantage plans online</Link></li>
															<li key={57}><Link to="/meet-with-us/">Speak to a Plan Guide</Link></li>
															<li key={58}><Link to="/plans/benefits-savings-support/">Find out about any extra benefits</Link>, such as fitness programs, hearing aid discounts and more.</li>
														</ul>
													</li>
													<li key={6}><Link to="/meet-with-us/request-consultation/">Arrange a personal consultation</Link> with a UVM Health Advantage Plan Guide.</li> 
													{/* <li>Determine which options are best for you. <Link to="#">Use our helpful checklist</Link>.</li> */}
												</ul>
											</div>
										</div>
									</div>

									<div class="accordion-item">
										<button type="button" id="accordion-trigger-02" class="accordiontrigger" aria-controls="accordion-content-02">
											When you retire
										</button>
										<div id="accordion-content-02" class="accordion-content" aria-labelledby="accordion-trigger-02" aria-hidden="true">
											<div class="contentwrap">
												<ul key={7}>
													<li key={71}>Time to enroll. You can enroll in Medicare up to two months after your coverage ends.</li>
													<li key={72}>If you haven&rsquo;t yet enrolled in Part A and Part B, <a href="http://ssa.gov/" class="external">contact the Social Security Administration</a>.</li>
													<li key={73}>To enroll in Part C (Medicare Advantage) and Part D, contact a UVM Health Advantage Plan Guide.</li>
													<li key={74}>If you haven&rsquo;t already done so, you should compare plans to determine which best meets your needs.</li>
												</ul>
											</div>
										</div>
									</div>
									
									<div class="accordion-item">
										<button type="button" id="accordion-trigger-03" class="accordiontrigger" aria-controls="accordion-content-03">
											Two months after you retire 
										</button>
										<div id="accordion-content-03" class="accordion-content" aria-labelledby="accordion-trigger-03" aria-hidden="true">
											<div class="contentwrap">
												<ul>
													<li key={8}>You must be enrolled in Part A, B and D (or creditable coverage) by now to avoid paying late enrollment penalties.</li>
												</ul>
											</div>
										</div>
									</div>
							
									<div class="accordion-item">
										<button type="button" id="accordion-trigger-04" class="accordiontrigger" aria-controls="accordion-content-04">
											Beyond two months after you retire
										</button>
										<div id="accordion-content-04" class="accordion-content" aria-labelledby="accordion-trigger-04" aria-hidden="true">
											<div class="contentwrap">
												<ul>
													<li key={9}>Other than the Special Enrollment Period (up to two months after losing your coverage), you can enroll during the Annual Open Enrollment Period between October 15 and December 7 each year</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<LearnMore />
							<SpeakWithAdvisor />
						</main>
						
						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default SixtyFivePlusWhen
