import * as React from "react"
import { Link } from "gatsby"

const SixtyFivePlus = () => {
	return (
		<div class="medicarebasics-subnav">
			<div class="constrained">
				<nav aria-label="65+ (First Time Enrolling) Menu">
					<ul>
						<li><Link to="/medicare-basics/65-plus/where-to-start/" activeClassName="current">Where to Start?</Link></li>
						<li><Link to="/medicare-basics/65-plus/learn-how-medicare-works/" activeClassName="current">Learn How Medicare Works</Link></li>
						<li><Link to="/medicare-basics/65-plus/know-what-to-do-when/" activeClassName="current">Know What to Do When</Link></li>
					</ul>
				</nav>
			</div>
		</div>
	)
}

export default SixtyFivePlus;
